
import { UserManager } from "oidc-client";
import { useEffect } from 'react';
import { signinPopupCallback } from "../services/userService";
//import { useHistory } from 'react-router-dom'


function SigninPopupOidc() {

  //const history = useHistory(); // don't use main window's history - we're in a popup window!

  useEffect(() => {

    /* const userManager = new UserManager({});
    userManager.signinPopupCallback().catch(
      (err) => {
        console.log(err);
    }); */

    async function signinPopupAsync() {
      let url = '';
      try {
        const user = await signinPopupCallback();
        //url = (new URL((user ? '/loggedIn.html' : '/loggedIn.html'), window.location.origin)).pathname;
      } catch (e) {
        console.error(`User signin error: ${e}`);
      }
      // history.push(url);
      window.location.href = '/loggedIn.html'; // don't use main window's history - we're in a popup window!
    }
    signinPopupAsync();

  });

  return (
    null
  )
}

export default SigninPopupOidc;


  /* <Redirect to='/loggedIn.html' /> */
