import { Form } from 'antd';
import React from 'react';
import type { FormInstance } from 'antd/es/form';


export const EditableContext = React.createContext<FormInstance<any> | null>(null);

export interface EditableTableRowProps {
    index: number;
}

const EditableTableRow: React.FC<EditableTableRowProps> = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

export default EditableTableRow;