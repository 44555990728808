import { useEffect, useState, createContext } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import React, { FC, ReactNode } from 'react';
import SigninOidc from './pages/signin-oidc'
import SigninPopupOidc from './pages/signin-popup-oidc'
import SignoutOidc from './pages/signout-oidc'
import SilentRefreshOidc from './pages/silent-refresh-oidc'
import { useDispatch } from 'react-redux'
import userManager, { loadUserFromStorage } from './services/userService'
import AuthProvider from './components/AuthProvider'
import ProtectedRoute from './components/ProtectedRoute'
import { Spin } from 'antd';
import ReactDOM from 'react-dom/client';
import DataTable from './DataTable';
import { Layout, MenuProps, Menu, Row, Col, message } from 'antd';
import { ApolloClient, InMemoryCache, ApolloProvider, HttpLink } from '@apollo/client';
import { SettingOutlined, PieChartOutlined } from '@ant-design/icons';
import { ErrorBoundary } from 'react-error-boundary';
import Sider from "antd/lib/layout/Sider";
import ErrorContextProvider, { IErrorContext } from './ErrorContextProvider';

import './App.css';

const { Header, Footer, Content } = Layout;

const SKIP_IDENTITYSERVER = (process.env.REACT_APP_AUTH_SKIP_IDENTITYSERVER || '').toLowerCase() === 'true';

const client = new ApolloClient({
  //// uri: 'http://graphql-dev.fm360.info/graphql', // has to be inserted into ux12 hosts
  //// uri: 'http://192.168.178.44:5170/graphql',  // VS debugger has to listen on any IP -> use * instead of localhost -> appsettings.yml -> Kestrel: Endpoints: Http: Url: "http://*:5170"
  // uri: 'https://pdb-api.fairweg.de/graphql',
  uri: process.env.REACT_APP_URI_GRAPHQL,
  cache: new InMemoryCache(),
  //credentials: 'omit', // include', 'same-origin'
  //headers: {
  //  'origin': 'http://localhost:3001',
  //},
  // link: new HttpLink({
  //   uri: process.env.REACT_APP_URI_GRAPHQL,
  //   fetchOptions: {
  //     mode: 'no-cors'
  //   }
  // }),
});

const items: MenuProps['items'] = [
  {
    label: 'PDB Management',
    key: 'productDb',
    icon: <PieChartOutlined />,
    children: [
      /*{
        label: 'Administration',
        type: 'group',
        children: [
          {
            label: 'tbd.',
            key: 'setting:3',
            disabled: true,
          }
        ],
      },*/
      {
        label: 'Tools',
        type: 'group',
        children: [
          {
            label: 'Accommodation Data Mapping',
            key: 'accommodationDataMapping',
            icon: <SettingOutlined />,
          },
          {
            label: 'tbd.',
            key: 'setting:2',
            disabled: true,
          },
        ],
      },
    ],
  },
];

const selectedMenuItems: string[] = ['accommodationDataMapping'];

const onMenuClick: MenuProps['onClick'] = (e) => {
  console.log('click ', e);
  //setSelectedMenuItems(e.key);
};

export const AppErrorContext = createContext<IErrorContext | null>(null);

const ErrorFallback = ({ error, resetErrorBoundary, autoRecover }: { error: any, resetErrorBoundary: () => void, autoRecover?: boolean }) => {
  return (
    <div role="alert">
      <p>ERROR BDRY: Something went wrong:</p>
      <pre>{error?.message}</pre>
    </div>
  );
}



function App() {

  const dispatch = useDispatch();
  const [isUserChecked, setUserChecked] = useState(false);



  function AppPane(): any {
    return (
      <ApolloProvider client={client}>
        <Layout style={{ height: '100vh' }}>
          <Header className="site-layout-sub-header-background" style={{ paddingTop: 0, paddingLeft: 0, height: 60, zIndex: 1000, backgroundColor: '#95C11F' }}>
            <Row wrap={false}>
              <Col flex="190px">
                <Menu onClick={onMenuClick} selectedKeys={selectedMenuItems} theme="dark" mode="horizontal" items={items} style={{ backgroundColor: '#95C11F', height: 60 }} />
              </Col>
              <Col flex="300px">
                <span style={{ fontSize: 14, fontWeight: 'bold', color: '#fff', marginLeft: 0 }} >Accommodation Data Mapping</span>
              </Col>
              <Col flex="auto">
                <div style={{ textAlign: 'right', color: '#fff' }}>
                  FAIRWEG
                </div>
              </Col>
            </Row>
          </Header>
          <Layout>
            {/* <Sider></Sider> */}
            {/* <Content></Content> */}
            {/* <ErrorBoundary
          FallbackComponent={(error, resetErrorBoundary) => ErrorFallback({ error, resetErrorBoundary, autoRecover: false })}
          onError={(error: Error, info: { componentStack: string }) => {
            message.error(`Error: ${error.message}`);
            console.error(`Error: ${error.message}`, error.stack, info.componentStack);
          }}
          // onReset={() => {
          //   setErrorTriggered(true);
          // }}
      > */}
            {/* <ErrorContextProvider errorContext={AppErrorContext}> */}
            <DataTable />
            {/* </ErrorContextProvider> */}
            {/* </ErrorBoundary> */}
          </Layout>
          {/* <Footer style={{ paddingTop: 16, paddingLeft: 18, height: 50, textAlign: 'center' }}>
  <div><span style={{ fontStyle: 'normal' }} >Powered by</span> <span style={{ color: '#666', fontWeight: 'bold' }} ><span style={{ color: '#f00' }}>FM</span>360°</span> Dynamic Database Adapter</div>
</Footer> */}
        </Layout>
      </ApolloProvider>
    );
  }

  useEffect(
    () => {
      (async () => {
        //localStorage.removeItem('persist:root'); //localStorage.clear(); // !!! temporary fis to remove anything stored in localStorage !!!
        ////localStorage.clear();
        const user = await loadUserFromStorage(dispatch); // fetch current user from cookies - or redirect to login
        setUserChecked(true);
      })()
    }, [dispatch]
  );

  return !isUserChecked ?
    (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <Spin size='large' style={{ fontSize: '300%' }} />
      </div>
    ) :
    (
      <AuthProvider userManager={userManager} dispatch={dispatch}>
        <Router>
          <Routes>
            <Route path="/signin-oidc" Component={SigninOidc} />
            <Route path="/signin-popup-oidc" Component={SigninPopupOidc} /> {/* activate in apiService => catch 401 */}
            <Route path="/signout-oidc" Component={SignoutOidc} />
            <Route path="/silent-refresh-oidc" Component={SilentRefreshOidc} />
            <Route path="/" element={
              SKIP_IDENTITYSERVER ? (
                <ApolloProvider client={client}>
                  <Layout style={{ height: '100vh' }}>
                    <Header className="site-layout-sub-header-background" style={{ paddingTop: 0, paddingLeft: 0, height: 60, zIndex: 1000, backgroundColor: '#95C11F' }}>
                      <Row wrap={false}>
                        <Col flex="190px">
                          <Menu onClick={onMenuClick} selectedKeys={selectedMenuItems} theme="dark" mode="horizontal" items={items} style={{ backgroundColor: '#95C11F', height: 60 }} />
                        </Col>
                        <Col flex="300px">
                          <span style={{ fontSize: 14, fontWeight: 'bold', color: '#fff', marginLeft: 0 }} >Accommodation Data Mapping</span>
                        </Col>
                        <Col flex="auto">
                          <div style={{ textAlign: 'right', color: '#fff' }}>
                            FAIRWEG
                          </div>
                        </Col>
                      </Row>
                    </Header>
                    <Layout>
                      <DataTable />
                    </Layout>
                  </Layout>
                </ApolloProvider>
              ) : (
                <ProtectedRoute>
                  <ApolloProvider client={client}>
                    <Layout style={{ height: '100vh' }}>
                      <Header className="site-layout-sub-header-background" style={{ paddingTop: 0, paddingLeft: 0, height: 60, zIndex: 1000, backgroundColor: '#95C11F' }}>
                        <Row wrap={false}>
                          <Col flex="190px">
                            <Menu onClick={onMenuClick} selectedKeys={selectedMenuItems} theme="dark" mode="horizontal" items={items} style={{ backgroundColor: '#95C11F', height: 60 }} />
                          </Col>
                          <Col flex="300px">
                            <span style={{ fontSize: 14, fontWeight: 'bold', color: '#fff', marginLeft: 0 }} >Accommodation Data Mapping</span>
                          </Col>
                          <Col flex="auto">
                            <div style={{ textAlign: 'right', color: '#fff' }}>
                              FAIRWEG
                            </div>
                          </Col>
                        </Row>
                      </Header>
                      <Layout>
                        <DataTable />
                      </Layout>
                    </Layout>
                  </ApolloProvider>
                </ProtectedRoute>
              )

            } />

          </Routes>
        </Router>
      </AuthProvider>
    )
}

export default App;
