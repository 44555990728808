import { Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { login } from '../services/userService'
import { RootState } from '../reducers/rootReducer';
import { Spin } from 'antd';
import React from 'react';


function ProtectedRoute({ children, path, component: Component, ...rest }: {
  [x: string]: any;
  children?: any;
  component?: any;
}): any {

  const user = useSelector((state: RootState) => state.auth.user); // this one is from redux localStorage only

  if (user) {
    return children ?
      (<>
        {children}
      </>) :
      (
        <Component />
      );
  }

  login();

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
      <Spin size='large' style={{ fontSize: '300%' }} />
    </div>
  );
}

export default ProtectedRoute;