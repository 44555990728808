import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAuthState, AuthAction, IAuthUserLoadPayload, IAuthUser, IAuthSetTokenPayload } from './authReducer';
import { User } from 'oidc-client';
import { AppThunk, AppDispatch } from '../components/Store'


const initialState: IAuthState = {
    user: null,
    isLoadingUser: false
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        authUserLoad(state, action: PayloadAction<IAuthUserLoadPayload>) {
            //state.user = action.payload.user;
            //state.isLoadingUser = false;
            //const expirationDate = printUserExpirationDate(state.user);
            //const expired = isUserExpired(state.user);
            return {
                ...state,
                isLoadingUser: false,
                user: action.payload.user
            };
        },
        authUserIsLoading(state, action: PayloadAction<AuthAction.LOADING_USER>) {
            //state.isLoadingUser = true;
            return {
                ...state,
                isLoadingUser: true
            };
        },
        authUserUnload(state, action: PayloadAction<AuthAction.USER_EXPIRED | AuthAction.STORE_USER_ERROR | AuthAction.USER_SIGNED_OUT>) {
            //state.user = null;
            //state.isLoadingUser = false;
            return {
                ...state,
                user: null,
                isLoadingUser: false
            }
        },
        authStoreToken(state, action: PayloadAction<IAuthSetTokenPayload>) {
            state.token = action.payload.token;
        }
    }
});

export const authUserLoad = ( // use this variant if dispatcher /action has side effects like async, random...   -> https://www.mattbutton.com/redux-made-easy-with-redux-toolkit-and-typescript/
    user: User | IAuthUser | null
):
    AppThunk => async (dispatch: any) => {
        const authUser = user ? (JSON.parse(JSON.stringify(user)) as IAuthUser) : null;
        if (authUser) {
            authUser.expired = user?.expired ?? true;
        }
        const newAuthUserLoadPayload: IAuthUserLoadPayload = {
            user: authUser,
            actionType: AuthAction.STORE_USER
        };
        dispatch(authSlice.actions.authUserLoad(newAuthUserLoadPayload));
    }


export type AuthState = ReturnType<typeof authSlice.reducer>;
export const { authUserIsLoading, authUserUnload, authStoreToken } = authSlice.actions;

export default authSlice.reducer;