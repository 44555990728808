import type { InputRef } from 'antd';
import { Form, Input } from 'antd';
import React, { useEffect, useRef, useState, useContext } from 'react';
import { IAccommodationInstanceMappingViewRecord } from './DataTable';
import { EditableContext } from './EditableTableRow';



export interface EditableTableCellProps {
    title: React.ReactNode;
    editable: boolean;
    children: React.ReactNode;
    dataIndex: string;
    record: IAccommodationInstanceMappingViewRecord;
    handleSave: (record: IAccommodationInstanceMappingViewRecord) => void;
}

const EditableTableCell: React.FC<EditableTableCellProps> = ({
    title, // column name
    editable,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef<InputRef>(null);
    const form = useContext(EditableContext)!;

    useEffect(() => {
        if (editing) {
            inputRef.current!.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({ [dataIndex]: (record as any)[dataIndex] });
    };

    const save = async () => {
        try {
            const values = await form.validateFields();
            toggleEdit();
            handleSave({ ...record, ...values });
        } catch (errInfo) {
            console.log('Save failed:', errInfo);
        }
    };

    let childNode = children;

    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{ margin: 0 }}
                name={dataIndex}
            // rules={[
            //   {
            //     required: true,
            //     message: `${title} is required.`,
            //   },
            // ]}
            >
                <Input ref={inputRef} onPressEnter={save} onBlur={save} />
            </Form.Item>
        ) : (
            <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
                {children}
            </div>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};

export default EditableTableCell;