// import { User } from "oidc-client";

export enum AuthAction {
    STORE_TOKEN = "STORE_TOKEN",
    STORE_USER = "STORE_USER",
    USER_SIGNED_OUT = "USER_SIGNED_OUT",
    USER_EXPIRED = "USER_EXPIRED",
    STORE_USER_ERROR = "STORE_USER_ERROR",
    LOADING_USER = "LOADING_USER"
}

export interface IAuthUser {
    access_token: string,
    id_token: string,
    scope: string,
    token_type: string,
    expires_at: number,
    expired: boolean,
    profile: any,
    state: any
}

export interface IAuthUserLoadPayload {
    user: IAuthUser | null,
    actionType: AuthAction.STORE_USER
}

export interface IAuthSetTokenPayload {
    token: string,
    actionType: AuthAction.STORE_TOKEN
}

export interface IAuthState {
    user: IAuthUser | null,
    token?: string,
    isLoadingUser: boolean
}
