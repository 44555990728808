//import * as FM360 from "../fm360";


export type ValueOf<T> = T[keyof T];

/* Alternative: x == null or x != null  */
export const isUndefinedOrNull = (obj: any) => obj == null; // == on null checks for undefined or null - as oppose to ===

//export const isNotUndefinedOrNull = (obj: any) => (typeof obj !== 'undefined' && obj !== null);

export const isNullOrEmpty = (s: string | null | undefined) => {
    return !s;
};


export const provideDefaultIfNullOrEmpty = (x: any, fb: any) => {
    return (isUndefinedOrNull(x) || x === '') ? fb : x;
}


export const isNullOrWhitespace = (s: string) => {
    return !s ?
        true :
        !(s.replace(/^\s+/, ''));
};


/** Gets index of element
*   Checks ary[elem} == crit
*   @param {Array} ary    Array of objects to search
*   @param {String} elem  name of object element to check by
*   @param {String} crit  criterion to match by
*   @return {Number} Position of object in array   */
export const findInArrayOfObjects = (ary: any, elem: string, crit: string | number) => {
    let pos = -1;
    if (!Array.isArray(ary) || ary.length === 0) {
        return -1;
    }
    ary.forEach((r: any, i: number, a: any[]) => {
        if (r && r[elem] && r[elem].toString().toLowerCase() === crit.toString().toLowerCase()) {
            pos = i;
            return false;
        }
    });
    return pos;
}


/** Converts passed parameter to array.<br />
*   Call: convertToArray.apply(this, arguments);
*   @param {Number/String/Boolean/Array}  val
*   @return {Array}    */
export const convertToArray = (val: any, separator?: string) => {
    if (isUndefinedOrNull(val)) {
        return [];
    }
    if (Array.isArray(val)) {
        return val; // nothing to do for arrays
    }
    else if ('string' === typeof val) {
        return val.split(separator ?? ','); // split strings
    }
    else {
        return [val]; // just push numbers
    }
}

/** Compare two arrays
    *   @param {Array}  a1
    *   @param {Array}  a2
    *   @param {Boolean}  doSort
    *   @return {Boolean}  true if arrays equal, false if different   */
export const compareArrays = (a1: any, a2: any, doSort: boolean) => {
    if (!Array.isArray(a1) || !Array.isArray(a2) || a1.length !== a2.length) {
        return false;
    }
    const arr1 = doSort ? a1.concat().sort() : a1;
    const arr2 = doSort ? a2.concat().sort() : a2;
    for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i])
            return false;
    }
    return true;
}



/** Compare two arrays
*   @param {Array}  a1
*   @param {Array}  a2
*   @param {Boolean}  doSort
*   @param {Boolean}  strict
*   @return {Boolean}  true if arrays equal, false if different   */
export const compareArraysOfObjects = (a1: any, a2: any, doSort: boolean, strict: boolean) => {
    if (!Array.isArray(a1) || !Array.isArray(a2) || a1.length !== a2.length) {
        return false;
    }
    const arr1 = doSort ? a1.concat().sort() : a1;
    const arr2 = doSort ? a2.concat().sort() : a2;
    for (let i = 0; i < arr1.length; i++) {
        for (let p in arr1[i]) {
            if (Object.prototype.hasOwnProperty.call(arr1[i], p)) {
                if (!Object.prototype.hasOwnProperty.call(arr2[i], p)) {
                    return false;
                }
                const p1 = strict ? arr1[i][p] : (typeof (arr1[i][p]) === 'undefined' ? '' : arr1[i][p].toString());
                const p2 = strict ? arr2[i][p] : (typeof (arr2[i][p]) === 'undefined' ? '' : arr2[i][p].toString());
                if (p1 !== p2) {
                    return false;
                }
            }
        }
    }
    return true;
}



export const isDate = (date: any) => {
    return typeof date?.getMonth === 'function';
}



export const objectToArray = (obj: any) => {
    const arr: any[] = [];
    if (!obj) {
        return arr;
    }
    Object.keys(obj).map((key, i) => arr.push(obj[key]));
    return arr;
}



export const arrayToObject = (arr: any[], key: string | number) => {
    const obj: any = {};
    if (!key || !Array.isArray(arr) || arr.length === 0) {
        return null;
    }
    arr.map((item) => {
        if (Object.prototype.hasOwnProperty.call(item, key)) {
            obj[key.toString()] = item;
        }
        return item;
    });
    return Object.keys(obj).length ?
        obj :
        null;
}

/* Use in posts with content-type application/x-www-form-urlencoded */
export const urlEncodeFormDataJson = (x: any) =>
    Object.keys(x).reduce((p, c) => `${p}&${c}=${encodeURIComponent(x[c])}`, '');

/* Use in posts with content-type multipart/form-data */
export const multipartEncodeFormDataJson = (x: any) => {
    const formData = new FormData();
    Object.keys(x).map((p) =>
        formData.append(p, (x[p] ?? ''))
    );
    return formData;
}




/** Adds class on Element level
*   @param {Element}  element This corresponds to *el.dom* in ExtJS, not to el!
*   @param {String}  className */
export const addClassToElement = (element: Element | HTMLElement | null | undefined, className: string | null | undefined) => {
    if (!className || !element || element.nodeType !== 1) {
        return element;
    }
    if (element.classList) {
        if (!element.classList.contains(className)) {
            element.classList.add(className);
            return element;
        }
    }
    if (typeof className === "string") {
        const classNames = (className || "").split(/\s+/);
        if (element.nodeType === 1) {
            if (!element.className) {
                element.className = className;
            } else {
                let newClassName = ` ${element.className} `;
                for (let c = 0, cl = classNames.length; c < cl; c++) {
                    if (className.indexOf(` ${classNames[c]} `) < 0) {
                        newClassName += ` ${classNames[c]} `;
                    }
                }
                element.className = newClassName.replace(/^\s+|\s+$/g, "");
            }
        }
    }
    return element;
}

/** Removes class on Element level
*   @param {Element}  element This corresponds to *el.dom* in ExtJS, not to el!
*   @param {String}  className */
export const removeClassFromElement = (element: Element | HTMLElement | null | undefined, className: string | null | undefined) => {
    if (!className || !element || element.nodeType !== 1) {
        return element;
    }
    if (element.classList?.contains(className)) {
        element.classList.remove(className);
        return element;
    }
    if (typeof className === "string" && className) {
        const classNames = className.split(/\s+/);
        if (element.nodeType === 1 && element.className) {
            let concatenatedClassNames = (` ${element.className} `).replace(/[\n\t]/g, " ");
            for (let c = 0, cl = classNames.length; c < cl; c++) {
                concatenatedClassNames = concatenatedClassNames.replace(new RegExp(`\\s+${classNames[c]}\\s+`, 'g'), " ");
            }
            element.className = concatenatedClassNames.replace(/^\s+|\s+$/g, "");
        }
    }
    return element;
}

export function joinStringsByUniqueSeparator(stringParts: string[], sep: string = '/') {
    return stringParts.map(part => {
        const part2 = part.endsWith(sep) ? part.substring(0, part.length - 1) : part;
        return part2.startsWith(sep) ? part2.substr(1) : part2;
    })
        .join(sep);
}

/* expect(pathJoin(['a', 'b', 'c', 'd'], '/')).toEqual('a/b/c/d');
expect(pathJoin(['a/', '/b/', 'c/', 'd'], '/')).toEqual('a/b/c/d');
expect(pathJoin(['http://abc.de', 'users/login'], '/')).toEqual('http://abc.de/users/login'); */


export const concatArgsBySeparator = (sep: string, ...args: (string | number | undefined | null)[]) => { // domain: string, channelId: number, slotNr: number, clipId: number, clipPriorityIndex: number
    return `${args?.filter(x => !isUndefinedOrNull(x))?.map(x => x?.toString())?.join(sep) ?? new Date().getTime().toString()}-`;
}

export const capitalizeFirstLetter = (s: string) => (s && s.length > 0) ? (s.charAt(0).toUpperCase() + s.substr(1)) : s;

export const lowercaseFirstLetter = (s: string) => (s && s.length > 0) ? (s.charAt(0).toLowerCase() + s.substr(1)) : s;

export const formatString = (tmpl: string | undefined, ...args: string[]) => {
    if (tmpl == null) {
        return '';
    }
    return tmpl.replace(/\{(\d+)\}/g, (m, i) => args[i] ?? '');
}


export const ellipsis = (
    value: string,
    len: number,
    truncateOnWordBoundary: boolean
) => {
    if (value && value.length > len) {
        if (truncateOnWordBoundary) {
            let vs = value.substr(0, len - 2);
            let index = Math.max(vs.lastIndexOf(' '), vs.lastIndexOf('.'), vs.lastIndexOf('!'), vs.lastIndexOf('?'));
            if (index === -1 || index < (len - 15)) {
                return `${value.substr(0, len - 3)}...`;
            }
            return `${vs.substr(0, index)}...`;
        }
        return `${value.substr(0, len - 3)}...`;
    }

    return value;
};


export const trySetPropsOrExec = (
    objects: (string | HTMLElement)[],
    nvc: Array<Array<any>>
) => {
    objects.forEach((o, i, a) => {
        const cmp = (typeof (o) == 'string' ? document.getElementById(o) : o) as any;
        if (cmp) {
            nvc.forEach((nv, j, aa) => {
                const functionOrProperty = nv[0];
                const valueOrArguments = nv[1];
                if (cmp[functionOrProperty] && typeof (cmp[functionOrProperty]) === 'function') {
                    if (valueOrArguments == null) {
                        cmp[functionOrProperty]();
                    } else {
                        cmp[functionOrProperty](valueOrArguments);
                    }
                } else {
                    cmp[functionOrProperty] = valueOrArguments;
                }
            });
        }
    });
};

export const querySelectorParent = (el: HTMLElement | null, selector: string) => {
    let isIDSelector = selector.indexOf("#") === 0
    if (selector.indexOf('.') === 0 || selector.indexOf('#') === 0) {
        selector = selector.slice(1)
    }
    while (el) {
        if (isIDSelector) {
            if (el.id === selector) {
                return el
            }
        }
        else if (el.classList?.contains(selector)) {
            return el;
        }
        el = el.parentElement;
    }
    return null;
}

export const swapItemInArray = (arr: any[], indexFrom: number, indexTo: number) => {
    [arr[indexFrom], arr[indexTo]] = [arr[indexTo], arr[indexFrom]];
}

export const arrayFromSwapItemInArray = (arr: any[], indexFrom: number, indexTo: number) => arr.map((val, idx) => {
    if (idx === indexFrom) return arr[indexTo];
    if (idx === indexTo) return arr[indexFrom];
    return val;
});

// export const isFolderType = (objectTypeID: number | string | undefined) => {
//     return (!objectTypeID ? false : (objectTypeID == FM360.ObjectType.Folder || objectTypeID == FM360.ObjectType.Site || objectTypeID == FM360.ObjectType.Campaign));
// }

export const findBy: any = (obj: any, prop: string, val: any, retProp?: string, recurseProp?: string) => {
    if (obj == null) return null;
    let result;
    if (Array.isArray(obj)) {
        for (const o of obj) {
            result = findBy(o, prop, val, retProp, recurseProp);
            if (result) {
                return (retProp) ? result[retProp] : result;
            }
        }
    }
    if (obj[prop] === val) {
        return (retProp) ? obj[retProp] : obj;
    }
    Object.keys(obj).forEach(p => {
        if (recurseProp ? (p === recurseProp) : (typeof obj[p] === 'object')) {
            result = findBy(obj[p], prop, val, retProp, recurseProp);
            if (result) {
                return (retProp) ? result[retProp] : result;
            }
        }
    });
    return (retProp) ? result[retProp] : result;
}

/** Inverses an object: value becomes property and vice versa.
*   @param {Object}  o Object to convert
*   @param {Boolean}  convertKeyToString Default: true
*   @return {String[][]}  multiDimArray  */
export const inverseObject = (o: any, convertKeyToString: boolean) => {
    const ret: any = {};
    Object.keys(o).forEach(key => {
        ret[convertKeyToString === false ? o[key] : o[key].toString()] = key;
    });
    return ret;
}


/** Inverses a twodimensional array: value becomes property and vice versa.
*   @param {Object}  a Array to convert
*   @return {String[][]}  multiDimArray  */
export const inverseKeyValueElementsInArray = (a: Array<any>) => { // inverseTwodimensionalArray
    const retAry: Array<any> = [];
    a.forEach(function (kv, i, a) {
        retAry.push([kv[1], kv[0]]);
    });
    return retAry;
}

export const renameObjectKey = (obj: any, oldKey: string, newKey: string) => {
    let newObj = {};
    obj && Object.keys(obj).forEach(key => {
        if (key === oldKey) {
            let newPair = { [newKey]: obj[oldKey] };
            newObj = { ...newObj, ...newPair }
        } else {
            newObj = { ...newObj, [key]: obj[key] }
        }
    });
    return newObj;
}


export const parseUrl = (url: string, convertNamesToLower: boolean) => {
    const parser = document.createElement('a');
    let searchObject: any = {};
    // Let the browser do the work
    parser.href = url;
    // Convert query string to object
    const queries = parser.search.replace(/^\?/, '').split('&');
    for (let i = 0; i < queries.length; i++) {
        const split = queries[i].split('=');
        const name = convertNamesToLower ? split[0].toLowerCase() : split[0];
        searchObject[name] = decodeURIComponent(split[1]);
    }
    return {
        protocol: parser.protocol,
        host: parser.host,
        hostname: parser.hostname,
        port: parser.port,
        pathname: parser.pathname,
        queryString: parser.search,
        queryStringObject: searchObject,
        queryStringArray: getUrlParameters(parser.search.replace(/^\?/, '')),
        hash: parser.hash
    };
}


export const getUrlParameters = (queryString: string) => {
    const retAry = [];
    const args = queryString.split("&");
    for (let i = 0; i < args.length; i++) {
        const nv = args[i].split("=");
        // If first entry with this name
        if (typeof nv[0] !== "undefined") {
            retAry.push([nv[0], decodeURIComponent(nv[1])]);
        }
    }
    return retAry;
}


export const getUrlParameter = (name: string) => {
    const param = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
    const regexS = `[\\?&]${param}=([^&#]*)`;
    const regex = new RegExp(regexS);
    const results = regex.exec(window.location.href);
    return results == null ? "" : results[1];
}


export const isInt = (value: any) => {
    return !isNaN(value) &&
        parseInt(Number(value).toString()) == value &&
        !isNaN(parseInt(value, 10));
}