import _ from "lodash";
import { Space, Switch, Button, Row, Col, Table, Tooltip, Spin } from 'antd';
import React, { useCallback, useState, useMemo, useContext, useReducer } from 'react';
import { useQuery, gql } from '@apollo/client';
import { useWhyDidYouUpdate } from 'ahooks';
import { EditOutlined, GlobalOutlined, FileTextOutlined } from '@ant-design/icons';
import type { TablePaginationConfig } from 'antd/es/table';
import type { FilterValue, SorterResult, TableRowSelection, TableCurrentDataSource } from 'antd/es/table/interface';
import type { EditableTableColumnTypeArray } from './EditableTableColumn';
import ColumnSearchFilter from './ColumnSearchFilter';
import EditableTableCell from './EditableTableCell';
import EditableTableRow from './EditableTableRow';
import { AppErrorContext } from './App';

export enum AccommodationProvider {
  None = 0,
  Fairweg = 1,
  Traveltainment = 2,
  Ecotrans = 3
}

enum DataProviderType {
  None = 0,
  Fairweg = 1,
  Fairunterwegs = 2,
  Destinet = 3
}

export interface TableFilterItem {
  field: string | null;
  operation: string | null;
  value: FilterValue | null;
};
export interface TableFilterParams extends Record<string, FilterValue | null> { };
export interface TableFilterOperations extends Record<string, string> { };

interface TablePaginationParams extends TablePaginationConfig {
  current: number;
  pageSize: number;
  total: number;
};

interface TableSortParams {
  field?: string;
  order?: string;
}

export interface IAccommodationInstanceMappingViewRecord {
  accommodationInstanceMappingId: number; // don't name it "id" if it is not unique - otherwise apollo client gets confused!
  accommodationId: number;
  accommodationInstanceId: number | null;
  mapRef: string | null;
  accommodationProviderId: number | null;
  accommodationProviderRef: string | null;
  semanticDistance: number;
  semanticMatchQuery: string | null;
  semanticMatchCandidatesCnt: number;
  geoDistance: number;
  matchSource: number;
  certificateId: number | null;
  certificateName: string | null;
  isConsideredMatch: boolean;
  increasedTolerance: boolean;
  isIncreasedToleranceTriggered: boolean;
  postType: number | null;
  giataRef: string | null;
  data: string | null;
  dataRef: string | null;
  postId: number | null;
  postDate: Date | null;
  postModified: Date | null;
  postStatus: string | null;
  author: string | null;
  isDeleted: boolean | null;
  isResolved: boolean | null;
  deletedTime: Date | null;
  resolvedTime: Date | null;
  //__typename:                    string;
}

interface IAccommodationInstanceMappingRecord {
  accommodationInstanceMappingId: number; // don't name it "id" if it is not unique - otherwise apollo client gets confused!
  accommodationId: number;
  accommodationProviderId: number;
  accommodationProviderRef: string | null;
  type: number;
  semanticDistance: number;
  semanticThreshold: number;
  semanticMatchQuery: string | null;
  semanticMatchCandidatesCnt: number;
  geoDistance: number;
  geoThreshold: number;
  qualityScore: number;
  matchSource: number;
  certificateId: number;
  isConsideredMatch: boolean;
  increasedTolerance: boolean;
  isIncreasedToleranceTriggered: boolean;
  isDeleted: boolean;
  isResolved: boolean;
  deletedTime: Date | null;
  resolvedTime: Date | null;
}

interface IAccommodationInstanceMappingMutationResultItem {
  accommodationId: number;
  accommodationProviderId: number;
  accommodationInstanceMappingId: number;
  accommodationProviderRef: string | null;
}

const getAccommodationInstanceDataMappingQuery = gql`
  query dataMappingPaginated(
    $skip: Int!
    $take: Int!
    $order:[VDataMappingSortInput!]
    $where: VDataMappingFilterInput
  ){
    dataMappingPaginated(
      skip: $skip,
      take: $take,
      order: $order,
      where: $where
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
      items {
        accommodationInstanceMappingId
        accommodationId
        accommodationInstanceId
        postType
        mapRef
        accommodationProviderId
        accommodationProviderRef
        semanticDistance
        semanticMatchQuery
        semanticMatchCandidatesCnt
        geoDistance
        matchSource
        certificateId
        certificateName
        isConsideredMatch
        increasedTolerance
        isIncreasedToleranceTriggered
        giataRef
        data
        dataRef
        postId
        postDate
        postModified
        postStatus
        author
        isDeleted
        isResolved
      }
    }
  }
`;

const updateAccommodationInstanceMappingMutation = gql`
  mutation updateAccommodationInstanceMapping (
    $items: [VDataMappingInput!]!
  ) {
    updateAccommodationInstanceMapping(
      input: {
        update: {
          items: $items
        }
      }
    ) {
      accommodationInstanceMappingMutationResult {
        statusCode
        message
        payload {
          items {
            accommodationId
            accommodationInstanceMappingId
            accommodationProviderId
            accommodationProviderRef
          }
        }
      }
      errors {
        ... on ArgumentNullError {
            message
            paramName
        }
        ... on AccommodationInstanceMappingMutationError {
            message
            accommodationId
        }
      }
    }
  }
`;

const getOrder = (sorter: TableSortParams) => {
  if (sorter.field != null) {
    return [{ [sorter.field as string]: `${sorter.order === 'ascend' ? 'ASC' : 'DESC'}` }];
  }
  return null;
};

const getRecordUniqueConstraint = (rec: IAccommodationInstanceMappingViewRecord | null | undefined, refOverride?: string | null) => {
  if (rec == null) {
    return "";
  }
  //const refHash = (refOverride || rec.accommodationProviderRef)?.split('').map(v => v.charCodeAt(0)).reduce((a, v) => a + ((a << 7) + (a << 3)) ^ v).toString(16) || '0';
  //return `${rec.accommodationId}-${rec.certificateId}-${refHash}`;
  return `${rec.accommodationInstanceMappingId}-${rec.accommodationInstanceId}`;  // don't name "accommodationInstanceMappingId" simply "id" as it is not unique - otherwise apollo client gets confused!
};


/*
  APP
*/
const DataTable: React.FC = () => {

  const [data, setData] = useState<IAccommodationInstanceMappingViewRecord[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [paginationParams, setPaginationParams] = useState<TablePaginationParams>({
    current: 1,
    pageSize: 25,
    total: 0
  });
  const [sortParams, setSortParams] = useState<TableSortParams>({
    field: 'accommodationId',
    order: 'ascend'
  });
  const [filterParams, setFilterParams] = useState<TableFilterParams>({ 'isDeleted': [false] }); // {col1:[1], col2: ['some text']}
  const [filterOperations, setFilterOperations] = useState<TableFilterOperations>({ 'isDeleted': 'eq', 'isResolved': 'eq' });
  const [dataIncludeDeleted, setDataIncludeDeleted] = useReducer((
    state: boolean,
    action: boolean
  ) => {
    setFilterParams(lastState => {
      if (action) {
        let newState = { ...lastState };
        delete newState['isDeleted'];
        return newState;
      } else {
        return {
          ...lastState,
          ...{ 'isDeleted': [false] }
        };
      }
    });
    return state === action ? state : action;
  }, false);
  const [dataIncludeResolved, setDataIncludeResolved] = useReducer((
    state: boolean,
    action: boolean
  ) => {
    setFilterParams(lastState => {
      if (action) {
        let newState = { ...lastState };
        delete newState['isResolved'];
        return newState;
      } else {
        return {
          ...lastState,
          ...{ 'isResolved': [false] }
        };
      }
    });
    return state === action ? state : action;
  }, false);

  const appErrorContext = useContext(AppErrorContext);


  const getWhere = (filters: TableFilterParams, filterOps: TableFilterOperations) => {
    let where: any = {};
    for (let columnName in filters) {
      if (columnName in filterOps) {
        var op = filterOps[columnName];
        where[columnName] = { [op]: (filters[columnName] as (boolean | React.Key)[])[0] };
      }
    }
    return where;
  };

  const { client, loading, error, refetch, fetchMore } = useQuery(getAccommodationInstanceDataMappingQuery, {
    fetchPolicy: "no-cache",
    variables: {
      skip: ((paginationParams.current) - 1) * (paginationParams.pageSize),
      take: paginationParams.pageSize,
      order: getOrder(sortParams),
      where: getWhere(filterParams, filterOperations)
    },
    onCompleted: (d) => {
      setData(d?.dataMappingPaginated?.items);
      setPaginationParams(state => {
        return state.total === d?.dataMappingPaginated?.totalCount ?
          state :
          {
            ...state,
            total: d?.dataMappingPaginated?.totalCount || 0
          };
      });
    },
    onError: (e) => {
      appErrorContext?.setError({ message: e.message } as Error);
    }
  });

  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<IAccommodationInstanceMappingViewRecord> | SorterResult<IAccommodationInstanceMappingViewRecord>[], // <-- when multiple sorting
    { action, currentDataSource }: TableCurrentDataSource<IAccommodationInstanceMappingViewRecord>  // action: "paginate", "sort", "filter"
  ) => {
    switch (action) {

      case 'paginate': {
        setPaginationParams({
          pageSize: pagination.pageSize || paginationParams.pageSize,
          current: pagination.current || paginationParams.current,
          total: paginationParams.total
        });
        fetchMore({
          variables: {
            skip: ((pagination?.current || 1) - 1) * (pagination?.pageSize || 0),
            take: pagination?.pageSize,
            order: getOrder(sortParams),
            where: getWhere(filterParams, filterOperations)
          },
        });
        break;
      }

      case 'sort': {
        const sorting = {
          field: (sorter as any).field,
          order: (sorter as any).order
        };
        setSortParams({
          ...sorting
        });
        setPaginationParams({
          ...paginationParams,
          current: 1
        });
        refetch({
          skip: 0,  // (paginationParams.current - 1) * paginationParams.pageSize,
          take: paginationParams.pageSize,
          order: getOrder(sorting),
          where: getWhere(filterParams, filterOperations)
        });
        break;
      }

      case 'filter': {
        const filterItems = {
          ...filterParams,
          ...filters
        };
        //setFilterParams(filterItems);
        setPaginationParams({
          ...paginationParams,
          current: 1,
        });
        // refetch({
        //   skip: 0,  // (paginationParams.current - 1) * paginationParams.pageSize,
        //   take: paginationParams.pageSize,
        //   order: getOrder(sortParams),
        //   where: getWhere(filterItems, filterOperations)
        // });
        break;
      }

      default:
        break;
    }
  };

  const columnSearchFilterProps = ColumnSearchFilter('semanticMatchQuery', 'contains', setFilterParams, setFilterOperations);
  //const refSearchFilterProps = ColumnSearchFilter('mapRef', setFilterParams);

  const rowSelection: TableRowSelection<IAccommodationInstanceMappingViewRecord> = {
    selectedRowKeys,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
    ],
    onChange: (selectedRowKeys: React.Key[], selectedRows: IAccommodationInstanceMappingViewRecord[]) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      setSelectedRowKeys(selectedRowKeys);
    },
    getCheckboxProps: (record: IAccommodationInstanceMappingViewRecord): any => ({
      disabled: record.isDeleted,
      name: record.accommodationId.toString(),
    }),
  };

  const columnsDefinition = useMemo((): EditableTableColumnTypeArray => ([
    // {
    //  -> rowSelection
    // },
    {
      title: 'Links',
      key: 'externalLinks',
      width: 70,
      fixed: true,
      render: (_, r) => (
        <div style={{ width: 70 }}>
          <Tooltip placement="topLeft" title={`${r.mapRef}`}><a href={`${r.mapRef}`} target='_blank' rel='noreferrer'><FileTextOutlined style={{ color: "green", fontSize: '15px', marginRight: 5 }} /></a></Tooltip>
          <Tooltip placement="topLeft" title={`https://buchung.fairweg.de/offer?fwpid=fuw&ibe=hotel&direct=1&aid=${r.postId}`}><a href={`https://buchung.fairweg.de/offer?fwpid=fuw&ibe=hotel&direct=1&aid=${r.postId}`} target='_blank' rel='noreferrer'><GlobalOutlined style={{ color: "green", fontSize: '15px', marginRight: 5 }} /></a></Tooltip>
          <Tooltip placement="topLeft" title={`https://fairweg.de/wp-admin/post.php?action=edit&post=${r.dataRef}`}><a href={`https://fairweg.de/wp-admin/post.php?action=edit&post=${r.dataRef}`} target='_blank' rel='noreferrer'><EditOutlined style={{ color: "green", fontSize: '15px', marginRight: 5 }} /></a></Tooltip>
        </div>
      ),
    },
    {
      title: 'Nr.',
      key: 'nr',
      width: 40,
      fixed: true,
      ellipsis: {
        showTitle: false,
      },
      render: (_, r, i) => {
        //const recordIndex = getRecordIndex(r);
        //const ix = data.dataMappingPaginated.items.findIndex((x: IAccommodationInstanceDataMappingRecord) => getRecordIndex(x) === recordIndex) + 1;
        const ix = ((paginationParams.current - 1) * paginationParams.pageSize) + i + 1;
        //return `${ix}`;
        return (<span style={{ color: r.isDeleted ? 'red' : 'black' }}>{ix}</span>);
      },
    },
    {
      title: 'PID',
      dataIndex: 'accommodationId',
      width: 70,
      fixed: true,
      sorter: true,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Cert ?',
      dataIndex: 'certificateName',
      width: 100,
      fixed: true,
      sorter: true,
      ellipsis: {
        showTitle: false,
      },
      render: (v, r) => (
        <Tooltip placement="topLeft" title={`${v} (${r.certificateId} / ${r.accommodationInstanceMappingId})`}>
          {v}
        </Tooltip>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'semanticMatchQuery',
      width: 200,
      fixed: true,
      sorter: true,
      ellipsis: {
        showTitle: false,
      },
      render: (v) => (
        <Tooltip placement="topLeft" title={v}>
          {v}
        </Tooltip>
      ),
      // filterDropdown: () => {
      //   const f = (props: FilterDropdownProps) => null;
      //   return f({} as FilterDropdownProps);
      // },
      // filterSearch: true,
      ...columnSearchFilterProps,
    },
    {
      title: 'Data Source Ref',
      dataIndex: 'mapRef',
      width: 300,
      sorter: true,
      editable: true,
      ellipsis: {
        showTitle: false,
      },
      render: (v) => (
        <Tooltip placement="topLeft" title={v}>
          {v ? `...${v.replace(/^https:\/\/destinet.eu\/who-who\/market-place\/certifiers-section\/[^/]+/, '')}` : ''}
        </Tooltip>
      ),
      //...refSearchFilterProps,
    },
    {
      title: 'RefDistc',
      dataIndex: 'accommodationProviderRef',
      width: 80,
      sorter: true,
      render: (v, r) => {
        const isNull = v == null || v === '';
        const isEqual = v === r.mapRef;
        return (
          <Tooltip placement="topLeft" title={`...${v?.replace(/^https:\/\/destinet.eu\/who-who\/market-place\/certifiers-section/, '')}`}>
            <span style={{ fontWeight: 'normal', color: isEqual ? 'green' : (isNull ? 'black' : 'red') }}>{isEqual ? 'match' : (isNull ? '-' : 'diff')}</span>
          </Tooltip>
        )
      },
    },
    {
      title: 'GeoDistc',
      dataIndex: 'geoDistance',
      width: 100,
      sorter: true,
      render: (v) => {
        return v < 10 ? v : Math.round(v);
      },
    },
    {
      title: 'SemDistc',
      dataIndex: 'semanticDistance',
      width: 100,
      sorter: true,
    },
    {
      title: 'SemCnt',
      dataIndex: 'semanticMatchCandidatesCnt',
      width: 100,
      sorter: true,
    },
    {
      title: 'IsMatch',
      dataIndex: 'isConsideredMatch',
      width: 80,
      sorter: true,
      render: (_, r) => (<span style={{ fontWeight: 'bold', color: r.isConsideredMatch === true ? 'green' : 'red' }}>{r.isConsideredMatch === true ? 'x' : '-'}</span>),
    },
    {
      title: 'IsForced',
      dataIndex: 'increasedTolerance',
      width: 80,
      sorter: true,
      render: (_, r) => (<span style={{ fontWeight: 'bold', color: r.increasedTolerance === true ? 'green' : 'red' }}>{r.increasedTolerance === true ? 'x' : '-'}</span>),
    },
    {
      title: 'ForcedMt',
      dataIndex: 'isIncreasedToleranceTriggered',
      width: 80,
      sorter: true,
      render: (_, r) => (<span style={{ fontWeight: 'bold', color: r.isIncreasedToleranceTriggered === true ? 'green' : 'red' }}>{r.isIncreasedToleranceTriggered === true ? 'x' : '-'}</span>),
    },
    {
      title: 'GIATA',
      dataIndex: 'giataRef',
      width: 80,
      sorter: true
    },
    {
      title: 'AID',
      dataIndex: 'dataRef',
      width: 80,
      sorter: true,
      render: (v) => (
        <a href={`https://buchung.fairweg.de/offer?fwpid=fuw&ibe=hotel&direct=1&aid=${v}`} target='_blank' rel='noreferrer'>{v}</a>
      ),
    },
    {
      title: 'PostInit',
      dataIndex: 'postDate',
      width: 90,
      sorter: {
        compare: (a, b) => (a.postDate ? (new Date(a.postDate)).getTime() : 0) - (b.postDate ? (new Date(b.postDate)).getTime() : 0)
      },
      render: (v, r, i) => {
        const date = new Date(v);
        //return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
        return `${date.toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' })}`;
      },
    },
    {
      title: 'PostMod',
      dataIndex: 'postModified',
      width: 90,
      sorter: {
        compare: (a, b) => (a.postDate ? (new Date(a.postDate)).getTime() : 0) - (b.postDate ? (new Date(b.postDate)).getTime() : 0)
      },
      render: (v, r, i) => {
        const date = new Date(v);
        return `${date.toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' })}`;
      },
    },
    {
      title: 'Author',
      dataIndex: 'author',
      width: 80,
      sorter: true
    },
    {
      title: 'Pub',
      dataIndex: 'postStatus',
      width: 50,
      sorter: true,
      //render: (v) => (<span style={{ fontWeight: 'bold', color: v === 'publish' ? 'green' : 'red' }}>{v === 'publish' ? 'P' : 'D'}</span>),
      render: (v) => (<span style={{ fontWeight: 'bold', color: v === 'publish' ? 'green' : 'red' }}>{v === 'publish' ? 'x' : '-'}</span>),
    },
    // {
    //   title: 'Gender',
    //   dataIndex: 'gender',
    //   filters: [
    //     { text: 'Male', value: 'male' },
    //     { text: 'Female', value: 'female' },
    //   ],
    // },
    // {
    //   title: 'Tags',
    //   key: 'tags',
    //   dataIndex: 'tags',
    //   render: (_, { tags }) => (
    //     <>
    //       {tags.map((tag) => {
    //         let color = tag.length > 5 ? 'geekblue' : 'green';
    //         if (tag === 'loser') {
    //           color = 'volcano';
    //         }
    //         return (
    //           <Tag color={color} key={tag}>
    //             {tag.toUpperCase()}
    //           </Tag>
    //         );
    //       })}
    //     </>
    //   ),
    // },
    // {
    //   title: 'Action',
    //   key: 'action',
    //   width: 100,
    //   render: (_, r) => (
    //     <a>xxx</a>
    //   ),
    // },
  ]), [columnSearchFilterProps, paginationParams]);

  const handleSave = useCallback((row: IAccommodationInstanceMappingViewRecord, rowIndex: number) => {
    let ix = -1;
    const updatedRecordIndex = getRecordUniqueConstraint(row);
    const dataItems = data?.filter((rec: IAccommodationInstanceMappingViewRecord, i: number) => {
      const recordIndex = getRecordUniqueConstraint(rec);
      if (updatedRecordIndex === recordIndex) {
        ix = i;
        return true;
      }
      return false;
    });
    if (dataItems?.length > 0) {
      if (dataItems.length > 1) {
        let x = 1;
      }
      const dataItem = dataItems[0];
      const newData = [...data];
      newData[ix] = { ...row };
      setData(newData);
    }
  }, [data]);

  const columns = columnsDefinition.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: IAccommodationInstanceMappingViewRecord) => ({
        //style: { backgroundColor: 'red !important' },
        record,
        editable: record.isDeleted ? false : col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const components = {
    body: {
      row: EditableTableRow,
      cell: EditableTableCell,
    },
  };

  const handleUpdateClick = useCallback(
    async (e: any) => {
      if (selectedRowKeys.length > 0) {
        const selectedDataItems = data?.filter((rec: IAccommodationInstanceMappingViewRecord, i: number) => { //selectedRowKeys.forEach((ix, i, a) =>
          const recordIndex = getRecordUniqueConstraint(rec);
          return selectedRowKeys.includes(recordIndex);
        });
        if (selectedRowKeys?.length !== selectedDataItems?.length) {
          let xx = 1;
        }
        const mutationInputItems = selectedDataItems?.map(rec => {
          let vrec: any = { ...rec };
          delete vrec['__typename'];
          return vrec as IAccommodationInstanceMappingViewRecord;
        });
        //const mutationInputItems = selectedDataItems;
        if (mutationInputItems?.length === 0) {
          return;
        }
        const response = await client.mutate({
          mutation: updateAccommodationInstanceMappingMutation,
          fetchPolicy: "no-cache",
          variables: {
            "items": mutationInputItems
          },
          //awaitRefetchQueries: true,
        });
        const items = response.data?.updateAccommodationInstanceMapping?.accommodationInstanceMappingMutationResult?.payload.items as IAccommodationInstanceMappingMutationResultItem[];
        if (mutationInputItems?.length > 0 && mutationInputItems.length !== items.length) {
          let xx = 1;
        }
        if (items?.length > 0) {
          refetch({
            skip: (paginationParams.current - 1) * paginationParams.pageSize,
            take: paginationParams.pageSize,
            order: getOrder(sortParams),
            where: null
          });
        }
      }
    }, [client, data, paginationParams, refetch, selectedRowKeys, sortParams]
  );

  const handleSuggestClick = (e: any) => {
    const dataItems: string[] = [];
    data?.forEach((rec) => {
      if (
        rec.mapRef === rec.accommodationProviderRef ||
        (
          rec.isConsideredMatch === true &&
          rec.matchSource === 1 &&
          (
            rec.geoDistance === 0 ||
            rec.semanticDistance <= 0.1 ||
            (rec.accommodationProviderRef && (rec.accommodationProviderRef === rec.mapRef)) ||
            (rec.geoDistance <= 0.2 && rec.semanticDistance < 0.35)
          )
        )
      ) {
        dataItems.push(getRecordUniqueConstraint(rec));
      }
    });
    if (dataItems.length > 0) {
      setSelectedRowKeys(dataItems);
    }
  };

  const getPageSizeOptions = (opt: number[], includeTotal: boolean) => {
    let options = opt.filter(r => r <= paginationParams.total);
    return includeTotal ? options.concat([paginationParams.total]) : options;
  };

  useWhyDidYouUpdate(`App`, { client, data, paginationParams, refetch, selectedRowKeys, sortParams, handleUpdateClick, columns, columnsDefinition, loading, handleTableChange, error, fetchMore, getAccommodationInstanceDataMappingQuery, setPaginationParams, setSortParams, filterParams, setTableParams: setFilterParams, handleSave, columnSearchFilterProps });


  return loading ?
    (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
      <Spin size='large' style={{ fontSize: '300%' }} />
    </div>) :
    error ?
      (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', color: 'red', fontWeight: 'bold', fontSize: 16 }}>
        Error : {error.message}
      </div>) :
      (
        <>
          <Table
            components={components}
            columns={columns as EditableTableColumnTypeArray}
            dataSource={data}
            rowSelection={rowSelection}
            rowKey={(record) => getRecordUniqueConstraint(record)}
            onRow={(record, rowIndex) => {
              return {
                style: { color: record.isDeleted ? '#aaa' : 'inherit', backgroundColor: record.isDeleted ? '#ddd' : 'inherit' },
                // onClick: (event) => { }, // click row
                // onDoubleClick: (event) => { }, // double click row
                // onContextMenu: (event) => { }, // right button click row
                // onMouseEnter: (event) => { }, // mouse enter row
                // onMouseLeave: (event) => { }, // mouse leave row
              };
            }}
            pagination={{
              //defaultPageSize: 25, // no effect -> paginationParams
              pageSizeOptions: getPageSizeOptions([1, 10, 25, 50, 100, 200], true),
              position: ['bottomRight'],
              showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
              ...paginationParams
            }}
            loading={loading}
            onChange={handleTableChange}
            title={() => (
              <Row justify="start">
                <Col flex="auto">
                  <div style={{ textAlign: 'left' }}>
                    <Button type="primary" onClick={async (e) => handleUpdateClick(e)} style={{ marginLeft: 18, marginRight: 20 }}>
                      Update selected
                    </Button>
                    <Button type="primary" onClick={async (e) => void (0)} style={{ marginRight: 20 }} disabled>
                      Resolve selected
                    </Button>
                    <Button type="default" onClick={(e) => handleSuggestClick(e)} style={{ marginRight: 20 }}>
                      Suggest selections
                    </Button>
                    <Space align="center" style={{ marginBottom: 16, marginRight: 20 }}>
                      Deleted: <Switch checked={dataIncludeDeleted} onChange={setDataIncludeDeleted} />
                    </Space>
                    {/* <Space align="center" style={{ marginBottom: 16, marginRight: 20 }}>
                      Resolved: <Switch checked={dataIncludeResolved} onChange={setDataIncludeResolved} />
                    </Space> */}
                  </div>
                </Col>
              </Row>
            )}
            footer={() => (
              <div style={{ textAlign: 'center', fontSize: 11 }}>
                <span style={{ color: '#bbb', paddingRight: 4 }} >Powered by</span> <a href='https://www.syntax-neue-medien.de/de/portfolio/fm360/' target='_blank' rel="noreferrer"><span style={{ color: '#666', fontWeight: 'bold' }} ><span style={{ color: '#f00' }}>FM</span>360°</span></a> <span style={{ color: '#444' }}>Dynamic Database Adapter</span>
              </div>
            )}
            size="small"
            sticky={true}
            bordered={true}
            //rowClassName={() => 'editable-row'}
            //scroll={{ x: 'max-content', y: 'max-content' }}
            scroll={{ x: 1820 }}
            //style={{ width: 1800 }} // not necessary - maybe in nested content mode?
            tableLayout="fixed"
          // summary={() => (
          //   <Table.Summary fixed='bottom'>
          //     <Table.Summary.Row>
          //       <Table.Summary.Cell index={0} colSpan={columnsDefinition.length + 1} >
          //         <div style={{ textAlign: 'left' }}>
          //           <Button type="primary" onClick={(e) => handleUpdateClick(e)} style={{ marginLeft: 55, marginRight: 10 }}>
          //             Update selected
          //           </Button>
          //           <Button type="default" onClick={(e) => handleSuggestClick(e)}>
          //             Suggest selections
          //           </Button>
          //         </div>
          //       </Table.Summary.Cell>
          //     </Table.Summary.Row>
          //   </Table.Summary>
          // )}
          />
        </>)
    ;
}

export default DataTable;
