import React, { useEffect } from 'react'
import { signinRedirectCallback } from '../services/userService'
import { useNavigate } from 'react-router-dom'


function SigninOidc() {

  let navigate = useNavigate();

  useEffect(() => {
    async function signinAsync() {
      let url = '';
      try {
        const user = await signinRedirectCallback();
        url = new URL(user?.state?.url || '/').pathname;
      } catch (e) {
        console.error(`User signin error: ${e}`);
      }
      navigate(url || '/');
    }
    signinAsync();
  }, [navigate]);

  return (
    <div>
      {/* Redirecting... */}
    </div>
  )
}

export default SigninOidc;
