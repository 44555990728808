import React, { useEffect, useRef } from "react";
import { Dispatch } from "redux";
import setAuthHeader from "../utils/setAuthHeader";
import { printUserExpirationDate } from "../services/userService";
import { User, UserManager } from "oidc-client";
import { authUserLoad } from '../reducers/authSlice';
import * as utils from "../utils/functions";


export default function AuthProvider({
  userManager: manager,
  dispatch,
  children
}: {
  userManager: UserManager;
  dispatch: Dispatch<any>;
  children: any;
}): any {

  let userManager = useRef<UserManager>();
  //const dispatch = useDispatch();

  useEffect(() => {
    userManager.current = manager;

    const onUserLoaded = (user: User) => {
      console.log(
        `User ${user.profile.given_name} loaded. Expires: ${printUserExpirationDate(user)}. Setting authHeader to ${utils.ellipsis(user.access_token, 100, false)}`
      );
      setAuthHeader(user.access_token); // maybe move to dispatch::authUserLoad
      dispatch(authUserLoad(user));
      //dispatch(authUserLoad({user: user, actionType: AuthAction.STORE_USER}));
    };

    const onUserUnloaded = () => {
      setAuthHeader(null);
      console.log(`user unloaded`);
    };

    const onAccessTokenExpiring = () => {
      console.log(`user token expiring`);
    };

    const onAccessTokenExpired = () => {
      console.log(`user token expired`);
    };

    const onUserSignedOut = () => {
      console.log(`user signed out`);
    };

    // events for user
    userManager.current.events.addUserLoaded(onUserLoaded);
    userManager.current.events.addUserUnloaded(onUserUnloaded);
    userManager.current.events.addAccessTokenExpiring(onAccessTokenExpiring);
    userManager.current.events.addAccessTokenExpired(onAccessTokenExpired);
    userManager.current.events.addUserSignedOut(onUserSignedOut);

    // Specify how to clean up after this effect:
    return function cleanup() {
      userManager?.current?.events.removeUserLoaded(onUserLoaded);
      userManager?.current?.events.removeUserUnloaded(onUserUnloaded);
      userManager?.current?.events.removeAccessTokenExpiring(
        onAccessTokenExpiring
      );
      userManager?.current?.events.removeAccessTokenExpired(onAccessTokenExpired);
      userManager?.current?.events.removeUserSignedOut(onUserSignedOut);
    };
  }, [manager, dispatch]);

  return React.Children.only(children);
}
