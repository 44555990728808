import { UserManager, User } from "oidc-client";
import { Dispatch } from "redux";
import { AuthAction, IAuthUser } from "../reducers/authReducer";
import { authUserLoad, authUserUnload } from '../reducers/authSlice';
import setAuthHeader from "../utils/setAuthHeader";
// import * as FM360 from "../fm360";



// let config = {
//   accessTokenExpiringNotificationTime: 30, // The number of seconds *before an access token is to expire* to raise the accessTokenExpiring event - *NOT* the frequency on which to raise the event
//   authority: FM360.appSettings["hosting.app.auth.authorityUrl"],
//   automaticSilentRenew: true,
//   clearStaleState: true,
//   client_id: FM360.appSettings["hosting.app.auth.clientID"],
//   extraQueryParams: FM360.appSettings["hosting.app.auth.extraQueryParams"],
//   loadUserInfo: false,
//   post_logout_redirect_uri: FM360.appSettings["hosting.app.auth.postLogoutRedirectUrl"],
//   redirect_uri: FM360.appSettings["hosting.app.auth.redirectUrl"],
//   response_type: FM360.appSettings["hosting.app.auth.responseType"],
//   scope: FM360.appSettings["hosting.app.auth.scope"],
//   silent_redirect_uri: FM360.appSettings["hosting.app.auth.silentRedirectUrl"],
// };

// if (FM360.appSettings["hosting.app.auth.popupRedirectUrl"]) {
//   config = {
//     ...config,
//     ...{
//       popup_redirect_uri: FM360.appSettings["hosting.app.auth.popupRedirectUrl"],
//       popupWindowFeatures: FM360.appSettings["hosting.app.auth.popupWindowFeatures"],
//       popupWindowTarget: FM360.appSettings["hosting.app.auth.popupWindowTarget"]
//     }
//   };
// }

const config = {
  authority: process.env.REACT_APP_AUTH_AUTHORITY,
  client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
  redirect_uri: process.env.REACT_APP_AUTH_REDIRECT_URI,
  response_type: process.env.REACT_APP_AUTH_RESPONSE_TYPE,
  scope: process.env.REACT_APP_AUTH_SCOPE,
  post_logout_redirect_uri: process.env.REACT_APP_AUTH_POST_LOGOUT_REDIRECT_URI,
  automaticSilentRenew: !!process.env.REACT_APP_AUTH_AUTOMATICSILENTRENEW,
  silent_redirect_uri: process.env.REACT_APP_AUTH_SILENT_REDIRECT_URI,
  accessTokenExpiringNotificationTime: Number(process.env.REACT_APP_AUTH_ACCESSTOKENEXPIRINGNOTIFICATIONTIME), // The number of seconds *before an access token is to expire* to raise the accessTokenExpiring event - *NOT* the frequency on which to raise the event
  clearStaleState: true,
  loadUserInfo: false,

  popup_redirect_uri: process.env.REACT_APP_AUTH_POPUP_REDIRECT_URI,
  popupWindowFeatures: 'location=no,toolbar=no,width=600,height=600,left=100,top=100',
  popupWindowTarget: '_blank',

  extraQueryParams: {
    customer_id: process.env.REACT_APP_AUTH_QUERYPARAMS_CUSTOMER_ID,
    locale: process.env.REACT_APP_AUTH_QUERYPARAMS_LOCALE,
  },
};

// const config = {
//   // //authority: "https://ux12.fm360.info:5001",
//   // // authority: "https://id-test.fm360.info",
//   //authority: "https://id-dev.fm360.info:5443", // = 192.168.178.44
//   authority: "https://identityserver.fairweg.de",
//   ////authority: "https://ux12.fm360.info:5443",
//   client_id: "react.test.client",
//   redirect_uri: "http://ux12.fm360.info:3001/signin-oidc",
//   response_type: "id_token token",
//   scope: "openid profile fm360api fm360profile",
//   post_logout_redirect_uri: "http://ux12.fm360.info:3001",
//   automaticSilentRenew: true,
//   silent_redirect_uri: "http://ux12.fm360.info:3001/silent-refresh-oidc",
//   accessTokenExpiringNotificationTime: 30, // The number of seconds *before an access token is to expire* to raise the accessTokenExpiring event - *NOT* the frequency on which to raise the event
//   clearStaleState: true,
//   loadUserInfo: false,

//   popup_redirect_uri: "http://ux12.fm360.info:3001/signin-popup-oidc",
//   popupWindowFeatures: 'location=no,toolbar=no,width=600,height=600,left=100,top=100',
//   popupWindowTarget: '_blank',

//   extraQueryParams: {
//     customer_id: "34",
//     locale: "de-DE",
//   },
// };

//// const config = {
////   authority: "https://test.fairweg.de/",
////   client_id: "wp.admin.client",
////   client_secret: "secret",
////   redirect_uri: "http://ux12.fm360.info:3001/signin-oidc",
////   scope: "openid profile",
////   post_logout_redirect_uri: "http://ux12.fm360.info:3001",
////   grant_type: "authorization_code",
////   response_type: "code",
////   // automaticSilentRenew: true,
////   // silent_redirect_uri: "https://ux12.fm360.info:3001/silent-refresh-oidc",
////   accessTokenExpiringNotificationTime: 30, // The number of seconds *before an access token is to expire* to raise the accessTokenExpiring event - *NOT* the frequency on which to raise the event
////   clearStaleState: true,
////   loadUserInfo: false,
////   popup_redirect_uri: "https://ux12.fm360.info:3001/signin-popup-oidc",
////   popupWindowFeatures: 'location=no,toolbar=no,width=600,height=600,left=100,top=100',
////   popupWindowTarget: '_blank',
//// };


const userManager = new UserManager(config);

export async function loadUserFromStorage(dispatch: Dispatch<any>) {
  try {
    let user = await userManager.getUser();
    if (!user) {
      dispatch(authUserUnload(AuthAction.STORE_USER_ERROR));
      return null;
    }
    /* if (user.expired) {
      dispatch(authUserUnload(AuthAction.USER_EXPIRED));
      return;
    } */
    dispatch(authUserLoad(user));
    setAuthHeader(user.access_token);
    return user;
  } catch (e) {
    console.error(`User not found: ${e}`);
    dispatch(authUserUnload(AuthAction.STORE_USER_ERROR));
    return null;
  }
}


export function login(forceLogin = false) {
  signinRedirect(forceLogin);
}

export async function loginPopup(dispatch?: Dispatch<any>, forceLogin = false) {
  try {
    const user = await signinRedirectPopup(forceLogin);
    if (dispatch) {
      dispatch(authUserLoad(user));
    }
    setAuthHeader(user.access_token);
    return user;
  } catch (e) {
    console.error('FM360 login popup failed. Check if popup blocker is active');
    console.error(e);
    //alert(`FM360 login popup failed. Check if popup blocker is active.`);
    return null;
  }
}



export function signinRedirect(forceLogin: boolean) {
  const state = { state: { url: window.location.href } };
  return forceLogin ?
    userManager.signinRedirect({ ...state, ...{ prompt: "login" } }) : // https://github.com/IdentityModel/oidc-client-js/issues/1070  :  Call Oidc.UserManager.signinRedirect() when an access token is already expired bypasses an actual signin
    userManager.signinRedirect(state); // uses SSO, so user will get new token wthout login (but not per silentRefresh, but after expiration of token) based on credentials stored in cookie; if you want to show login prompt anyway, use {prompt: "login"} parameter
}

export function signinRedirectCallback() {
  return userManager.signinRedirectCallback();
}


export async function signinRedirectPopup(forceLogin: boolean) {
  const state = { state: { url: window.location.href } };
  return await forceLogin ?
    userManager.signinPopup({ ...state, ...{ prompt: "login" } }) : // https://github.com/IdentityModel/oidc-client-js/issues/1070  :  Call Oidc.UserManager.signinRedirect() when an access token is already expired bypasses an actual signin
    userManager.signinPopup(state); // uses SSO, so user will get new token wthout login (but not per silentRefresh, but after expiration of token) based on credentials stored in cookie; if you want to show login prompt anyway, use {prompt: "login"} parameter
}

export function signinPopupCallback() {
  return userManager.signinPopupCallback();
}

export function signoutRedirect() {
  userManager.clearStaleState();
  userManager.removeUser();
  return userManager.signoutRedirect();
}

export function signoutRedirectCallback() {
  userManager.clearStaleState();
  userManager.removeUser();
  return userManager.signoutRedirectCallback();
}

export function signinSilentCallback() {
  return userManager.signinSilentCallback();
}



export function printUserExpirationDate(user: User | IAuthUser | null) {
  if (user?.expires_at) {
    const expirationDate = new Date(user.expires_at * 1000);
    return expirationDate ? expirationDate.toLocaleString("de-DE") : "---";
  }
  return null;
}

export function isUserExpired(user: User | IAuthUser | null) {
  if (!user || !user.expires_at) {
    return true;
  }
  const date = new Date();
  const tsnow = Math.round(Number(date) / 1000);
  const expired = user.expires_at < tsnow;
  if (expired) {
    console.log("EXPIRED");
  }
  return expired;
}

export default userManager;


/* const config = {
  //authority: "https://ux12.fm360.info:5001",
  authority: "https://id-test.fm360.info",
  //authority: "https://ux12.fm360.info:5443",
  client_id: "react.test.client",
  redirect_uri: "https://ux12.fm360.info:3001/signin-oidc",
  response_type: "id_token token",
  scope: "openid profile fm360api fm360profile",
  post_logout_redirect_uri: "https://ux12.fm360.info:3001",
  automaticSilentRenew: true,
  silent_redirect_uri: "https://ux12.fm360.info:3001/silent-refresh-oidc",
  accessTokenExpiringNotificationTime: 30, // The number of seconds *before an access token is to expire* to raise the accessTokenExpiring event - *NOT* the frequency on which to raise the event
  clearStaleState: true,
  loadUserInfo: false,

  popup_redirect_uri: "https://ux12.fm360.info:3001/signin-popup-oidc",
  popupWindowFeatures: 'location=no,toolbar=no,width=600,height=600,left=100,top=100',
  popupWindowTarget: '_blank',

  extraQueryParams: {
    customer_id: "90",
    locale: "de-DE",
  },
}; */