import { configureStore, Action } from '@reduxjs/toolkit';
import { Middleware } from 'redux';
import { ThunkAction } from 'redux-thunk';
import rootReducer, { RootState } from '../reducers/rootReducer';
import reduxLogger from 'redux-logger';
import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'

//import { createStore } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
//import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web




const middlewares: Middleware[] = [];
const disableReduxLogger = true;

if (process.env.NODE_ENV === `development` && !disableReduxLogger) {
  ////const { reduxLogger } = require(`redux-logger`);  // doesn't work somehow
  middlewares.push(reduxLogger);
}


const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth', 'ui']
  //blacklist: ['data', 'tabHistory']
  //stateReconciler: autoMergeLevel2
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const Store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [
        FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER,
        /* 'tabHistory/tabHistory',
        'comboOptions/addComboOptionsStoreItem',
        'comboOptions/removeComboOptionsStoreItem', */
      ],
    },
  }).concat(middlewares)
});


/* if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./combineReducers', () => {
    const newRootReducer = require('./combineReducers').default;
    store.replaceReducer(newRootReducer);
  })
} */

export type AppDispatch = typeof Store.dispatch;

export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

export const persistor = persistStore(Store);

export default Store;
