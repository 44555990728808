import _ from "lodash";
import type { InputRef } from 'antd';
import { Space, Input, Button } from 'antd';
import React, { useRef, useState, useReducer } from 'react';
import type { ColumnType } from 'antd/es/table';
import type { FilterDropdownProps } from 'antd/es/table/interface';
import { SearchOutlined, CloseOutlined } from '@ant-design/icons';
import type { FilterConfirmProps } from 'antd/es/table/interface';
import Highlighter from 'react-highlight-words';
import { ErrorBoundary } from 'react-error-boundary';
import { useWhyDidYouUpdate } from 'ahooks';
import { IAccommodationInstanceMappingViewRecord, TableFilterParams, TableFilterOperations } from './DataTable';


// const ColumnSearchFilter2 = (
//     {
//         dataIndex,
//         defaultFilterOperation,
//         setFilterParams,
//     }:
//         {
//             dataIndex: string,
//             defaultFilterOperation: string,
//             setFilterParams: React.Dispatch<React.SetStateAction<TableFilterParams>>,
//         }
// ): ColumnType<IAccommodationInstanceMappingViewRecord> => {
//     var t = dataIndex;
//     return null as unknown as ColumnType<IAccommodationInstanceMappingViewRecord>;
// };


const ColumnSearchFilter = (
    dataIndex: string,
    defaultFilterOperation: string,
    setFilterParams: React.Dispatch<React.SetStateAction<TableFilterParams>>,
    setFilterOperations: React.Dispatch<React.SetStateAction<TableFilterOperations>>,
): ColumnType<IAccommodationInstanceMappingViewRecord> => {
    const searchInput = useRef<InputRef>(null);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const [filterOperation, setFilterOperation] = useReducer((
        state: string,
        action: string
    ) => {
        setFilterOperations(lastState => {
            return lastState[dataIndex] === action ?
                lastState :
                {
                    ...lastState,
                    ...{ [dataIndex]: action }
                };
        });
        return state === action ? state : action;
    }, defaultFilterOperation
    );


    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        columnName: string,
        close: () => void,
    ) => {
        confirm();
        const text = selectedKeys[0];
        setFilterOperation(defaultFilterOperation);
        setSearchText(text);
        setSearchedColumn(columnName);
        setFilterParams(lastState => {
            return {
                ...lastState,
                ...{ [columnName]: [text] }
            };
        });
        close();
    };

    const handleReset = (columnName: string, clearFilters: () => void, close: () => void) => {
        clearFilters();
        setSearchText('');
        setFilterParams(lastState => {
            let newState = { ...lastState };
            delete newState[columnName];
            return newState;
        });
        close();
    };

    const filterDropdown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }: FilterDropdownProps): React.ReactNode => (
        <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            <Input
                ref={searchInput}
                placeholder={searchText ? searchText : `Search ${dataIndex}`}
                value={selectedKeys[0]}
                onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex, close)}
                style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
                <Button type="primary" icon={<SearchOutlined />} size="small" style={{ width: 90 }} onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex, close)} >
                    Search
                </Button>
                <Button size="small" style={{ width: 90 }} onClick={() => clearFilters && handleReset(dataIndex, clearFilters, close)} >
                    Reset
                </Button>
                <Button type="link" size="small" onClick={() => close()} >
                    <CloseOutlined />
                </Button>
            </Space>
        </div>
    );

    const filterIcon = (filtered: boolean): React.ReactNode => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    );

    const onFilter = (v: string | number | boolean, rec: IAccommodationInstanceMappingViewRecord): boolean => {
        return (rec as any)[dataIndex]
            .toString()
            .toLowerCase()
            .includes(typeof (v) === 'string' ? v.toLowerCase() : v);
    };

    const onFilterDropdownOpenChange = (visible: boolean): void => {
        if (visible) {
            setTimeout(() => searchInput.current?.select(), 100);
        }
    };

    const render = (text: string | null | undefined): React.ReactNode => (
        searchedColumn === dataIndex ? (
            <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text ? text.toString() : ''}
            />
        ) : (
            text
        )
    );

    useWhyDidYouUpdate(`ColumnSearchFilter`, { dataIndex, setFilterParams, searchText, setSearchText, searchedColumn, setSearchedColumn, searchInput });

    return {
        filterDropdown,
        filterIcon,
        onFilter,
        onFilterDropdownOpenChange,
        render
    };
}

export default ColumnSearchFilter;