import React, { useEffect } from "react";
import { UserManager } from "oidc-client";
import { signinSilentCallback } from "../services/userService";


function SilentRefreshOidc() {

  console.log("silently renewing tokens");

  useEffect(() => {
    const userManager = new UserManager({});
    userManager.signinSilentCallback().catch(
      (err) => {
        console.log(err);
    });
  });

  return <div>Refreshing token...</div>;
}

/* function SilentRefreshOidc() {
  console.log("silently renewing tokens");
  useEffect(() => {
    async function signinSilentAsync() {
      try {
        await signinSilentCallback();
      } catch (e) {
        console.error(e);
      }
    }
    signinSilentAsync();
  });

  return <div>Refreshing token...</div>;
} */

export default SilentRefreshOidc;
