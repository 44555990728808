import { combineReducers } from '@reduxjs/toolkit';
// import { QueryClient } from "react-query";
// import { QueryFilters } from 'react-query/types/core/utils';
import authReducer from './authSlice';
// import uiReducer from './uiSlice';
// import channelReducer from './dataChannelSlice';
// import apiBuilderReducer from './dataApiBuilderSlice';
// import clipReducer from './dataClipSlice';
// import templateReducer from './dataTemplateSlice';
// import comboOptionsReducer from './comboOptionsSlice';
// import tabHistoryReducer from './tabHistorySlice';
// import activeTabReducer from './activeTabSlice';
// import tabPanesReducer from './tabPanesSlice';
// import galleryReducer from './dataGallerySlice';
// import navigationReducer from './dataNavigationSlice';


// function emptyReducerFn(state = {}, action: any) {
//     return state;
// }


// export const resetQueries = (queryClient: QueryClient, filters?: QueryFilters, data?: any) => {
//     queryClient
//         .getQueryCache()
//         .findAll(filters)
//         .forEach((query) => {
//             query.setData(data ?? undefined);
//             query.invalidate();
//         });
//     queryClient.invalidateQueries(filters);
// };



const rootReducer = combineReducers({
    auth: authReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;

